const hasElementMoreSpaceOnTop = (element: Element): boolean => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  const vh: number = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const spaceTop = rect.y;
  const spaceBottom = vh - rect.bottom;
  return spaceTop > spaceBottom;
};

export default hasElementMoreSpaceOnTop;
