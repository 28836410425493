export const STATUS_SOSPESO = "0";
export const STATUS_ANNULLATO = "-1";
export const STATUS_COMPLETATO = "9999";

export const isStatusAnullato = (orderStatusId: string) =>
  orderStatusId === STATUS_ANNULLATO;
export const isStatusSospeso = (orderStatusId: string) =>
  orderStatusId === STATUS_SOSPESO;
export const isStatusMoreThanSospeso = (orderStatusId: string) =>
  Number.parseInt(orderStatusId) > Number.parseInt(STATUS_SOSPESO);
