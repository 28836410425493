import Button from "components/Button";
import WithUser, { UserProps } from "components/hoc/WithUser";
import categories from "core/api/categories";
import {
  ProductCategory,
  ProductionOrderProduct,
  Status,
  ToastStati,
} from "core/api/entities";
import orders from "core/api/orders";
import asyncLoadingDelegate from "core/asyncLoadingDelegate";
import mainBlockingLoadingDelegate from "core/mainBlockingLoadingDelegate";
import toastDelegate from "core/toastDelegate";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import StatusSelector from "components/StatusSelector";
import Grid from "components/Grid";
import styles from "./OrderDetail.module.scss";
import ProductionOrderItemOptions from "pages/orders/components/ProductionOrderItemOptions";

const saveStatus = async (
  product: ProductionOrderProduct,
  toSave: Status,
  t: Function
) => {
  asyncLoadingDelegate.start();
  try {
    await orders.changeStatus(product, toSave);
    toastDelegate.add({
      message: t("toast_generics.success"),
      toastStatus: ToastStati.Success,
    });
  } catch (e) {
    toastDelegate.add({
      message: t("toast_generics.error"),
      toastStatus: ToastStati.Danger,
    });
  } finally {
    asyncLoadingDelegate.stop();
  }
};

interface RouteParams {
  id: string;
}

function OrderDetail(props: UserProps) {
  const [t] = useTranslation();
  const { id } = useParams<RouteParams>();
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [product, setProduct] = useState<ProductionOrderProduct>();
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [originalSelectedStatus, setOriginalSelectedStatus] = useState<string>(
    ""
  );

  useEffect(() => {
    const load = async () => {
      try {
        const product: ProductionOrderProduct = await orders.detail(id);
        const list: ProductCategory[] = await categories.list();

        const category = list.find(
          (p: ProductCategory) => p.label === product.category
        );

        if (!category) {
          return;
        }

        const status = category.statuses.find(
          (s) => s.phaseId === product.status.phaseId
        );

        if (!status) {
          return;
        }

        setSelectedStatus(status.phaseId);
        setOriginalSelectedStatus(status.phaseId);
        setStatuses(category.statuses);
        setProduct(product);
      } catch (e) {
        toastDelegate.add({
          message: t("error"),
          toastStatus: ToastStati.Danger,
        });
      } finally {
        mainBlockingLoadingDelegate.stop();
      }
    };

    load();
  }, [id, t]);

  const onStatusChange = useCallback((status: string) => {
    setSelectedStatus(status);
  }, []);

  const reset = useCallback(() => {
    if (!product) {
      return;
    }

    if (selectedStatus === originalSelectedStatus) {
      return;
    }
    setSelectedStatus(originalSelectedStatus);
    const status = statuses.find((s) => s.phaseId === originalSelectedStatus);
    if (!status) {
      return;
    }

    saveStatus(product, status, t);
  }, [originalSelectedStatus, product, selectedStatus, statuses, t]);

  const confirm = useCallback(async () => {
    if (!product) {
      return;
    }

    const status = statuses.find((s) => s.phaseId === selectedStatus);
    if (!status) {
      return;
    }

    saveStatus(product, status, t);
    setOriginalSelectedStatus(status.phaseId);
  }, [product, selectedStatus, statuses, t]);

  if (!product) {
    return null;
  }

  return (
    <Grid px={4}>
      <h1>{`Ordine ${product.orderCode}/ ${product.internalSequence} di ${product.numberOfOrderDetails}`}</h1>
      <h2>{`${product.name} - ${product.description}`}</h2>
      <Grid col={6}>
        <img
          src={product.thumbnailUrl}
          alt={product.name}
          className={styles.productimage}
        />
      </Grid>
      <Grid col={6}>
        <ProductionOrderItemOptions product={product} />
      </Grid>
      <p>{t("change_order_status.help")}</p>
      <StatusSelector
        statuses={statuses}
        onStatusChange={onStatusChange}
        selectedStatus={selectedStatus}
      ></StatusSelector>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0.5rem",
        }}
      >
        <div>
          <Button
            data-testid="confirm"
            label={t("change_order_status.confirm")}
            primary={true}
            onClick={confirm}
          />{" "}
          <Button
            data-testid="reset"
            label={t("change_order_status.reset")}
            onClick={reset}
          />
        </div>
      </div>
    </Grid>
  );
}

export default WithUser(OrderDetail);
