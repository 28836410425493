import {
  OrderListResponse,
  WeeklyTimetable,
  Status,
  StatusEvent,
  ProductionOrderProduct,
} from "core/api/entities";
import {
  OrderList,
  OrderListPagination,
  ProductionOrderProductDTO,
} from "./dto";
import { HTTP_METHODS } from "./http";
import restCall from "./restCall";
import doMock from "./mock/doMock";
import getURLWithParams from "core/api/getURLWithParams";
import { ParamsObj } from "core/utils";

const { REACT_APP_BASE_URL } = process.env;

const shouldUseMock = (): boolean => {
  const { REACT_APP_USE_MOCK } = process.env;
  return REACT_APP_USE_MOCK === "true";
};

const doFetch = async (qsParams: ParamsObj): Promise<OrderListResponse> => {
  const url = getURLWithParams(`${REACT_APP_BASE_URL}/orders`, qsParams);
  return restCall(url).then((r) => r.json());
};

const mapResponse = (response: OrderListResponse): OrderList => {
  const { orders, pagination } = response;

  const doNext = (): Promise<OrderList> => {
    const { REACT_APP_BASE_URL } = process.env;
    return restCall(`${REACT_APP_BASE_URL}/${pagination.nextUrl}`)
      .then((r) => r.json())
      .then(mapResponse);
  };

  const doPrevious = (): Promise<OrderList> => {
    const { REACT_APP_BASE_URL } = process.env;
    return restCall(`${REACT_APP_BASE_URL}/${pagination.previousUrl}`)
      .then((r) => r.json())
      .then(mapResponse);
  };

  const newPagination: OrderListPagination = {
    pageCount: pagination.pageCount,
    start: pagination.start,
    totalCount: pagination.totalCount,
    hasNext: pagination.nextUrl.length > 0,
    hasPrevious: pagination.previousUrl.length > 0,
    doNext,
    doPrevious,
  };

  return {
    orders,
    pagination: newPagination,
  };
};

function list(qsParams = {}): Promise<OrderList> {
  const promise: Promise<OrderListResponse> = shouldUseMock()
    ? doMock()
    : doFetch(qsParams);
  return promise.then(mapResponse);
}

function timetable(): Promise<WeeklyTimetable[]> {
  const fetchTimetable = async (): Promise<WeeklyTimetable[]> => {
    const url = `${REACT_APP_BASE_URL}/orders/weekly-timetable`;
    return restCall(url).then((r) => r.json());
  };

  const promise: Promise<WeeklyTimetable[]> = fetchTimetable();
  return promise;
}

function detail(orderDetailId: string): Promise<ProductionOrderProductDTO> {
  return restCall(
    `${REACT_APP_BASE_URL}/orders/detail/${orderDetailId}`
  ).then((r) => r.json());
}

function getBOM(orderDetailId: string): Promise<Blob> {
  return restCall(
    `${REACT_APP_BASE_URL}/orders/detail/${orderDetailId}/bom`
  ).then((r) => r.blob());
}

function getRequirements(products: ProductionOrderProduct[]): Promise<Blob> {
  return restCall(
    `${REACT_APP_BASE_URL}/orders/production-requirement?details=${products.map(
      (p) => p.orderDetailUUID
    )}`
  ).then((r) => r.blob());
}

const statusHistory = (
  product: ProductionOrderProduct
): Promise<StatusEvent[]> => {
  return restCall(
    `${REACT_APP_BASE_URL}/orders/detail/${product.orderDetailUUID}/status-history`
  ).then((r) => r.json());
};

const changeStatus = (
  products: ProductionOrderProduct | ProductionOrderProduct[],
  status: Status
): Promise<void> => {
  const orderDetailIds = Array.isArray(products)
    ? products.map((p) => p.orderDetailUUID)
    : [products.orderDetailUUID];

  const body = {
    newPhaseId: status.phaseId,
    orderDetailIds,
  };

  return restCall(`${REACT_APP_BASE_URL}/orders/detail/status`, {
    method: HTTP_METHODS.POST,
    body,
  }).then(() => undefined);
};

const changeTrackingInfo = (
  product: ProductionOrderProduct,
  trackingInfo: string
): Promise<void> => {
  const body = { trackingInfo };

  return restCall(
    `${REACT_APP_BASE_URL}/orders/detail/${product.orderDetailUUID}/tracking`,
    {
      method: HTTP_METHODS.PATCH,
      body,
    }
  ).then(() => undefined);
};

function ordersAsCSV(qsParams = {}): Promise<any> {
  const url = getURLWithParams(
    `${REACT_APP_BASE_URL}/orders/export-csv`,
    qsParams
  );
  return restCall(url).then((r) => r.blob());
}

export default {
  getRequirements,
  ordersAsCSV,
  changeStatus,
  changeTrackingInfo,
  list,
  timetable,
  statusHistory,
  detail,
  getBOM,
};
