import currentLanguage from "core/currentLanguage";
import { ProductCategories } from "./dto";
import restCall from "./restCall";

const CACHE_KEY = "_categories_";

const getCacheKey = () => `${CACHE_KEY}#${currentLanguage.get()}`;

const { REACT_APP_BASE_URL } = process.env;

const cached = (): ProductCategories | undefined => {
  const value = sessionStorage.getItem(getCacheKey());
  if (value != null) {
    return JSON.parse(value);
  }
  return undefined;
};

const clearCache = () => sessionStorage.removeItem(getCacheKey());

function list(): Promise<ProductCategories> {
  const maybeCategories = cached();

  if (maybeCategories) {
    return Promise.resolve(maybeCategories);
  }

  return restCall(`${REACT_APP_BASE_URL}/categories`)
    .then((r) => r.json())
    .then((cat) => {
      sessionStorage.setItem(getCacheKey(), JSON.stringify(cat));
      return cat;
    });
}

export default {
  list,
  clearCache,
};
