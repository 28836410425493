import orders from "core/api/orders";
import download from "core/download";
import { ProductionOrderProduct, ToastStati } from "core/api/entities";
import toastDelegate from "core/toastDelegate";
import asyncLoadingDelegate from "core/asyncLoadingDelegate";
import i18n from "i18next";

const getRequirements = async (rows: ProductionOrderProduct[]) => {
  asyncLoadingDelegate.start();
  try {
    const blob = await orders.getRequirements(rows);
    const filename = `${i18n.t(
      "orders.requirements"
    )}_${new Date().getTime()}.pdf`;
    download(blob, filename);
  } catch (e) {
    toastDelegate.add({
      message: i18n.t("errors.requirements_download"),
      toastStatus: ToastStati.Danger,
    });
  } finally {
    asyncLoadingDelegate.stop();
  }
};

export default getRequirements;
