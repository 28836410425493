export type ChangeCallback = (language: string) => void;
type Subscribe = (cb: ChangeCallback) => () => void;

const DEFAULT_LANGUAGE = "en";

let language = DEFAULT_LANGUAGE;

let changeListeners: ChangeCallback[] = [];

const subscribe: Subscribe = (cb: ChangeCallback) => {
  changeListeners.push(cb);
  return () => {
    changeListeners = changeListeners.filter((t) => t !== cb);
  };
};

const get = () => language;
const set = (_language: string) => {
  language = _language;
  changeListeners.forEach((cb) => cb(language));
};

export default {
  subscribe,
  get,
  set,
};
