import React, { useMemo } from "react";
import styles from "components//OrderStatus.module.scss";
import { StyleClasses } from "core/utils.d";
import getClassNames from "core/getClassNames";
import { Status } from "core/api/entities";
import { isStatusAnullato } from "core/orderStatusUtils";

interface OrderStatusProps {
  onClick?: () => void;
  status: Status;
}

const OrderStatus = ({ status, onClick }: OrderStatusProps) => {
  const isCanceled = isStatusAnullato(status.phaseId);
  const isClickable = !!onClick && !isCanceled;
  const noOperation = () => {};

  const orderStatusNames = useMemo(() => {
    const orderStatusClasses: StyleClasses = {
      [styles.root]: true,
      [styles.root_modifierDanger]: isCanceled,
      [styles.root_modifierWarning]: status.isFirst,
      [styles.root_modifierSuccess]: status.isLast,
      [styles.root_modifierClickable]: isClickable,
    };

    return getClassNames(orderStatusClasses);
  }, [isCanceled, isClickable, status.isFirst, status.isLast]);

  return (
    <span
      onClick={isClickable ? onClick : noOperation}
      className={orderStatusNames}
    >
      {status.label}
      {isClickable && <span className="material-icons">arrow_right</span>}
    </span>
  );
};

export default OrderStatus;
