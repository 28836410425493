import React from "react";
import { useTranslation } from "react-i18next";
import { Tr, Th } from "components//Table";
import WithUser, { UserProps } from "components/hoc/WithUser";
import { UserRoles } from "core/api/entities";

interface ProductionOrderListItemHeaderComponentProps {}

const ProductionOrderListItemHeaderComponent = ({
  user,
}: ProductionOrderListItemHeaderComponentProps & UserProps) => {
  const [t] = useTranslation();
  return (
    <Tr>
      <Th>{t("orders.order_id")}</Th>
      <Th>{t("orders.date")}</Th>

      {user?.role !== UserRoles.Pm && <Th>{t("customer")}</Th>}
      {user?.role !== UserRoles.Pm && <Th>{t("orders.amount")}</Th>}
      {user?.role !== UserRoles.Retailer && <Th>{t("orders.channel")}</Th>}

      {/* <Th>{t("orders.company")}</Th> */}
      <Th>{t("orders.brand")}</Th>
      <Th>{t("orders.product")}</Th>
      <Th>{t("orders.image")}</Th>

      {user?.role !== UserRoles.Retailer && <Th>{t("orders.factory")}</Th>}

      <Th>{t("orders.category")}</Th>

      {user?.role !== UserRoles.Pm && <Th>{t("orders.price")}</Th>}

      <Th>{t("orders.status")}</Th>
      <Th>{t("orders.delivery")}</Th>
      <Th>{t("actions")}</Th>
    </Tr>
  );
};

export default WithUser<ProductionOrderListItemHeaderComponentProps>(
  ProductionOrderListItemHeaderComponent
);
