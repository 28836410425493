import React from "react";
import {
  WeeklyTimetable,
  DailyTimetable,
  TimetableOrderProduct,
} from "core/api/entities";
import { Table, Thead, Tbody, Tr, Th, Td } from "components/Table";
import TimetableOrderProductComponent from "pages/timetable/components/TimetableOrderProduct";
import toLocaleDay from "core/toLocaleDay";
import { useTranslation } from "react-i18next";

interface TimetableWeekProps {
  timetable: WeeklyTimetable;
}

const TimetableWeek = ({ timetable }: TimetableWeekProps) => {
  const [t] = useTranslation();

  return (
    <div>
      <h4>
        {t("dates.week")} {timetable.weekId}:{" "}
        {toLocaleDay(timetable.days[0].date)} &rarr;{" "}
        {toLocaleDay(timetable.days[timetable.days.length - 1].date)}
      </h4>
      <Table options={{ isFixed: true }}>
        <Thead>
          <Tr>
            {timetable.days.map((aDay: DailyTimetable, index: number) => {
              return (
                <Th
                  key={index}
                  options={{ isBorderLight: true, isTextAlignLeft: true }}
                >
                  {toLocaleDay(aDay.date)}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          <Tr style={{ verticalAlign: "top" }}>
            {timetable.days.map((aDay: DailyTimetable, index: number) => {
              return (
                <Td key={index} options={{ isTextAlignLeft: true }}>
                  {aDay.orderProducts.length === 0 && t("nothing_todo")}
                  {aDay.orderProducts.map(
                    (
                      aOrderProduct: TimetableOrderProduct,
                      aOrderProductIndex: number
                    ) => (
                      <TimetableOrderProductComponent
                        key={aOrderProductIndex}
                        timetableOrderProduct={aOrderProduct}
                      />
                    )
                  )}
                </Td>
              );
            })}
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
};

export default TimetableWeek;
