import React from "react";

interface ToLinkProps {
  children: string;
}

const parseHref = (url: string) => {
  if (!url.match("^https?://")) {
    return `http://${url}`;
  }

  return url;
};

const urlToLink = (url: string) => {
  return (
    '&nbsp;<a target="_black" href="' + parseHref(url) + '">' + url + "</a>"
  );
};

export const textToTextWithLink = (text: string): string => {
  if (!text) {
    return "";
  }

  const URL_REGEX = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi;

  let res = text
    .replace(/<[^>]+>/gm, "")
    .replace(URL_REGEX, (match, space, url) => {
      return urlToLink(url);
    });

  if (res.startsWith("&nbsp;")) res = res.replace("&nbsp;", "");

  return res;
};

const ToLink = ({ children }: ToLinkProps) => {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: textToTextWithLink(children) }}
    ></span>
  );
};

export default ToLink;
