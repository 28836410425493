import React, { useEffect, useState } from "react";
import { Toast } from "core/api/entities";
import ToastComponent from "components/Toast";
import toastDelegate from "core/toastDelegate";
import asyncLoadingDelegate, {
  LoadingMessageStatusProps,
} from "core/asyncLoadingDelegate";
import mainBlockingLoadingDelegate, {
  MainLoadingMessageStatusProps,
} from "core/mainBlockingLoadingDelegate";
import Loading from "components/Loading";
import AsyncLoading from "components/AsyncLoading";

const TOAST_LIVING_PERIOD_IN_MILLISECONDS = 4 * 1000;

const ToastContainer = ({ children }: React.HTMLProps<HTMLElement>) => {
  const [loading, setLoading] = useState<MainLoadingMessageStatusProps>(
    mainBlockingLoadingDelegate.getDefaultStatus()
  );
  const [toastArray, setToastArray] = useState<Toast[]>(toastDelegate.get());
  const [asyncLoadingStatus, setAsyncLoadingStatus] = useState<
    LoadingMessageStatusProps
  >(asyncLoadingDelegate.getDefaultStatus());

  useEffect(() => {
    const unsub = mainBlockingLoadingDelegate.subscribe(
      (newStatus: MainLoadingMessageStatusProps) => {
        setLoading(newStatus);
      }
    );
    return unsub;
  }, []);

  useEffect(() => {
    const unsub = toastDelegate.subscribe((newToastArray: Toast[]) => {
      setToastArray([...newToastArray]);
    });
    return unsub;
  }, []);

  useEffect(() => {
    const unsub = asyncLoadingDelegate.subscribe(
      (newStatus: LoadingMessageStatusProps) => {
        setAsyncLoadingStatus(newStatus);
      }
    );
    return unsub;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      for (let i = toastArray.length - 1; i >= 0; i--) {
        if (toastArray[i].birth + TOAST_LIVING_PERIOD_IN_MILLISECONDS < now)
          toastDelegate.remove(i);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [toastArray]);

  return (
    <div>
      <Loading
        isLoading={loading.isLoading}
        message={loading.message}
      ></Loading>
      <AsyncLoading
        isLoading={asyncLoadingStatus.isLoading}
        message={asyncLoadingStatus.message}
      />
      {toastArray.map((t, i) => (
        <ToastComponent
          key={i}
          index={i}
          message={t.message}
          toastStatus={t.toastStatus}
          onDismissClicked={() => toastDelegate.remove(i)}
        />
      ))}
      {children}
    </div>
  );
};

export default ToastContainer;
