import React from "react";
import { useTranslation } from "react-i18next";
import { ProductionOrder, ProductionOrderProduct } from "core/api/entities";
import {
  OpenProductionOrderListCallback,
  ProductionOrderSelectionCallback,
} from "./ProductionOrderList";
import ProductionOrderProcessDateMissingDays from "./ProductionOrderMissingDays";
import toLocale from "core/toLocaleDate";
import { Tr, Td } from "components//Table";
import Button from "components/Button";
import OrderStatus from "components/OrderStatus";
import isOrderStatusEditable from "core/isOrderStatusEditable";
import getBomService from "../services/getBomService";
import WithUser, { UserProps } from "components/hoc/WithUser";
import { UserRoles } from "core/api/entities";
import PriceComponent from "components/Price";

import ActionsMenu from "components/ActionsMenu";

export type ProductionOrderListItemProps = {
  isOdd?: boolean;
  idChecked: boolean;
  dataRow: ProductionOrder;
  productRowIndex: number;
  productRow: ProductionOrderProduct;
  onChangeStatus: (products: ProductionOrderProduct[]) => Promise<void>;
  onProductOpen?: OpenProductionOrderListCallback;
  onProductSelectedByCheckbox: ProductionOrderSelectionCallback;
  orderId: string;
};

const ProductionOrderListItemComponent = ({
  isOdd,
  idChecked,
  dataRow,
  productRow,
  productRowIndex,
  onProductOpen,
  onProductSelectedByCheckbox,
  onChangeStatus,
  orderId,
  user,
}: ProductionOrderListItemProps & UserProps) => {
  const [t] = useTranslation();

  const userRole = user ? user.role : UserRoles.Retailer;

  let productElements1 = [
    <Td key={1}>
      <input
        type="checkbox"
        disabled={!isOrderStatusEditable(userRole, productRow.status.phaseId)}
        checked={idChecked}
        onChange={() => onProductSelectedByCheckbox(productRow)}
        style={{ float: "left" }}
      />
      <span>
        {`${productRow.quantity}X`} {productRow.name}
      </span>
    </Td>,
    <Td key={2}>
      <img
        style={{ width: "100px" }}
        src={productRow.thumbnailUrl}
        alt={productRow.name}
      />
    </Td>,
  ];

  if (user?.role !== UserRoles.Retailer)
    productElements1.push(<Td key={3}>{productRow.factory}</Td>);
  productElements1.push(<Td key={4}>{productRow.category}</Td>);
  if (user?.role !== UserRoles.Pm)
    productElements1.push(
      <Td key={5}>
        <PriceComponent value={productRow.price} currency={dataRow.currency} />
      </Td>
    );

  const productElements = productElements1.concat([
    <Td key={6}>
      <OrderStatus
        onClick={
          isOrderStatusEditable(userRole, productRow.status.phaseId)
            ? () => onChangeStatus([productRow])
            : undefined
        }
        status={productRow.status}
      />
    </Td>,
    <Td key={7}>
      <ProductionOrderProcessDateMissingDays
        processDate={productRow.processDate}
        currentDate={new Date().getTime()}
      />
    </Td>,
    <Td key={8}>
      {onProductOpen && user?.role === UserRoles.Retailer && (
        <Button
          icon="open_in_new"
          primary={true}
          small={true}
          label={t("details")}
          onClick={() => onProductOpen(productRow, dataRow)}
        />
      )}
      {onProductOpen && user?.role !== UserRoles.Retailer && (
        <ActionsMenu
          actions={[
            {
              icon: "open_in_new",
              label: t("details"),
              onClick: () =>
                onProductOpen ? onProductOpen(productRow, dataRow) : () => {},
            },
            {
              icon: "insert_drive_file",
              label: t("orders.bom"),
              onClick: () => getBomService(productRow, orderId),
            },
          ]}
        />
      )}
    </Td>,
  ]);

  let orderElements = [
    <Td key={9} rowSpan={dataRow.products.length}>
      <span style={{ fontSize: "1.0rem" }}>{dataRow.orderExternalId}</span>
    </Td>,
    <Td key={10} rowSpan={dataRow.products.length}>
      {toLocale(dataRow.dateCreation)}
    </Td>,
  ];

  if (user?.role !== UserRoles.Pm)
    orderElements.push(
      <Td key={12} rowSpan={dataRow.products.length}>
        {dataRow.email}
      </Td>
    );
  if (user?.role !== UserRoles.Pm)
    orderElements.push(
      <Td key={13} rowSpan={dataRow.products.length}>
        <PriceComponent value={dataRow.amount} currency={dataRow.currency} />
      </Td>
    );
  if (user?.role !== UserRoles.Retailer)
    orderElements.push(
      <Td key={11} rowSpan={dataRow.products.length}>
        {dataRow.orderChannel}
      </Td>
    );

  orderElements.push(
    <Td key={14} rowSpan={dataRow.products.length}>
      {dataRow.brand}
    </Td>
  );

  if (productRowIndex > 0) {
    return <Tr isOdd={isOdd}>{productElements}</Tr>;
  } else {
    return <Tr isOdd={isOdd}>{orderElements.concat(productElements)}</Tr>;
  }
};

export default WithUser<ProductionOrderListItemProps>(
  ProductionOrderListItemComponent
);
