import React, { useEffect, useState } from "react";
import currentUser, { CurrentUser } from "core/currentUser";

export interface UserProps {
  user: CurrentUser;
}

export default function withUser<T>(
  Component: React.ComponentType<T & UserProps>
): React.ComponentType<T> {
  return (props: T) => {
    const [user, setUser] = useState<CurrentUser>(currentUser.get());

    useEffect(() => {
      setUser(currentUser.get());
      const unsub = currentUser.subscribe(setUser);
      return unsub;
    }, []);

    return <Component {...props} user={user} />;
  };
}
