import React from "react";
import styles from "components/Toast.module.scss";
import getClassNames from "core/getClassNames";
import { StyleClasses } from "core/utils.d";
import { ToastStati } from "core/api/entities";
import Button, { ButtonProps } from "components/Button";

interface Props extends React.HTMLProps<HTMLElement> {
  message: string;
  index: number;
  toastStatus?: ToastStati;
  onDismissClicked?: () => void;
  buttons?: ButtonProps[];
}

const Toast = ({
  message,
  index,
  toastStatus,
  onDismissClicked,
  buttons,
}: Props) => {
  let toastClasses: StyleClasses = {
    [styles.root]: true,
    [styles.root_modifierBig]: !!buttons && !!buttons.length,
    [styles.root_modifierDanger]: toastStatus === ToastStati.Danger,
    [styles.root_modifierWarning]: toastStatus === ToastStati.Warning,
    [styles.root_modifierSuccess]: toastStatus === ToastStati.Success,
  };
  const toastClassNames = getClassNames(toastClasses);
  let toastStyle = {
    bottom: index > 0 ? `${72 * index + 24}px` : "24px",
  };

  return (
    <div className={toastClassNames} style={toastStyle}>
      <div className={styles.root__icon}>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.3" />
          <path
            d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM21 25H19V19H21V25ZM21 17H19V15H21V17Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={styles.root__body}>
        <p className={styles.root__message}>{message}</p>
        {!!buttons && !!buttons.length && (
          <p>
            {buttons.map((b: ButtonProps, i: number) => (
              <span key={i}>
                <Button {...b} />
                &nbsp;
              </span>
            ))}
          </p>
        )}
      </div>
      <div className={styles.root__aside}>
        <button className={styles.root__dismiss} onClick={onDismissClicked}>
          ✕
        </button>
      </div>
    </div>
  );
};

export default Toast;
