import React from "react";
import { ReactComponent as Spinner } from "./spinner.svg";
import { useTranslation } from "react-i18next";
import styles from "components/Loading.module.scss";
interface LoadingProps {
  message?: string;
  isLoading: boolean;
}
export default ({ message, isLoading }: LoadingProps) => {
  const [t] = useTranslation();
  if (!isLoading) return <span></span>;
  return (
    <div className={styles.root}>
      <div>
        <Spinner></Spinner>
        <div className={styles.root__message}>{message || t("loading")}</div>
      </div>
    </div>
  );
};
