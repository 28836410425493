import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import styles from "components/ActionsMenu.module.scss";
import { StyleClasses } from "core/utils.d";
import getClassNames from "core/getClassNames";
import hasElementMoreSpaceOnTop from "components/helpers/hasElementMoreSpaceOnTop";

interface Action extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  icon?: string;
}

interface ActionsMenuProps {
  actions: Action[];
}

const ActionsMenu = ({ actions }: ActionsMenuProps) => {
  const [t] = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDisplayedTop, setIsDisplayedTop] = useState(false);

  let menuClasses: StyleClasses = {
    [styles.root__menu]: true,
    [styles.root__menu_modifierOpen]: isMenuOpen,
    [styles.root__menu_modifierDisplayedTop]: isDisplayedTop,
  };
  const menuClassNames = getClassNames(menuClasses);

  let backdropClasses: StyleClasses = {
    [styles.root__backdrop]: true,
    [styles.root__backdrop_modifierOpen]: isMenuOpen,
  };
  const backdropClassNames = getClassNames(backdropClasses);

  const onActionTriggered = (cb: any) => {
    return () => {
      setIsMenuOpen(false);
      cb();
    };
  };

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const element: Element = event.target as Element;
      setIsDisplayedTop(hasElementMoreSpaceOnTop(element));
      setIsMenuOpen(!isMenuOpen);
    },
    [isMenuOpen]
  );

  return (
    <div className={styles.root}>
      <Button
        icon="more_horiz"
        primary={true}
        small={true}
        label={t("actions")}
        onClick={toggleMenu}
      />
      <div className={menuClassNames}>
        {actions.map((action: Action, index: number) => (
          <button
            key={index}
            className={styles.root__action}
            onClick={onActionTriggered(action.onClick)}
          >
            {action.icon ? (
              <span className={`material-icons ${styles.root__icon}`}>
                {action.icon}
              </span>
            ) : (
              <span></span>
            )}
            {action.label}
          </button>
        ))}
      </div>
      <div
        className={backdropClassNames}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      ></div>
    </div>
  );
};

export default ActionsMenu;
