import React, { useCallback } from "react";
import WithLanguage, { LanguageProps } from "components/hoc/WithLanguage";
import styles from "components/LanguageSelector.module.scss";
import { StyleClasses } from "core/utils.d";
import getClassNames from "core/getClassNames";

interface Props {}

const LanguageSelector = ({
  language,
  changeLanguage,
}: Props & LanguageProps) => {
  let languageSelectClassesIt: StyleClasses = {
    [styles.root__option]: true,
    [styles.root__option_modifierSelected]: language === "it",
  };
  const languageClassNamesIt = getClassNames(languageSelectClassesIt);

  let languageSelectClassesEn: StyleClasses = {
    [styles.root__option]: true,
    [styles.root__option_modifierSelected]: language === "en",
  };

  const languageClassNamesEn = getClassNames(languageSelectClassesEn);

  const toEnglish = useCallback(() => changeLanguage("en"), [changeLanguage]);
  const toItalian = useCallback(() => changeLanguage("it"), [changeLanguage]);

  return (
    <span className={styles.root}>
      <ul className={styles.root__list}>
        <li className={languageClassNamesIt} onClick={toItalian}>
          it
        </li>
        <li className={languageClassNamesEn} onClick={toEnglish}>
          en
        </li>
      </ul>
    </span>
  );
};

export default WithLanguage<Props>(LanguageSelector);
