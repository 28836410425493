import React from "react";
import Header, { PageView, HeaderButton } from "components/Header";
import { logout } from "core/api/auth";
import { useTranslation } from "react-i18next";
import ProductionOrderItemModal from "pages/orders/components/ProductionOrderItemModal";
import ChangeOrderStatusModal from "pages/orders/components/ChangeOrderStatusModal";
import user from "core/api/user";
import categories from "core/api/categories";

interface PageWrapperProps {
  children: JSX.Element | JSX.Element[];
}

const PageWrapper = ({ children }: PageWrapperProps) => {
  const [t] = useTranslation();
  return (
    <div className="App">
      <Header
        buttons={[
          <HeaderButton
            key={1}
            label={t("logout")}
            onClick={async () => {
              await logout();
              user.clearCache();
              categories.clearCache();
              window.location.reload();
            }}
          />,
        ]}
        title={"DIS mto"}
      />
      <PageView>{children}</PageView>
      <ProductionOrderItemModal />
      <ChangeOrderStatusModal />
    </div>
  );
};

export default PageWrapper;
