import React from "react";
import styles from "components/Header.module.scss";
import LanguageSelector from "components/LanguageSelector";
import WithUser, { UserProps } from "components/hoc/WithUser";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserRoles } from "core/api/entities";

interface Header extends React.HTMLProps<HTMLElement> {
  title: string;
  buttons: React.HTMLProps<HeaderButton>[];
}

const Header = ({ title, buttons, user }: Header & UserProps) => {
  const [t] = useTranslation();

  const getCurrentUser = () => {
    if (!user) return <span></span>;
    return (
      <div className={styles.root__user}>
        {user.username}
        <div className={styles.root__role}>{user.role}</div>
      </div>
    );
  };

  return (
    <header className={styles.root}>
      <h1 className={styles.root__title}>{title}</h1>
      {user?.role !== UserRoles.Retailer && (
        <nav className={styles.root__navigation}>
          <NavLink
            exact
            className={styles.root__link}
            to="/"
            activeClassName={styles.root__link_modifierSelected}
          >
            {t("navigation.orders")}
          </NavLink>
          <NavLink
            className={styles.root__link}
            to="/timetable"
            activeClassName={styles.root__link_modifierSelected}
          >
            {t("navigation.timetable")}
          </NavLink>
        </nav>
      )}
      <div>
        {getCurrentUser()}
        <nav className={styles.root__navigation}>{buttons}</nav>
        <LanguageSelector />
      </div>
    </header>
  );
};

interface HeaderButton {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const HeaderButton = ({ label, onClick }: HeaderButton) => {
  return (
    <button onClick={onClick} className={styles.button}>
      {label}
    </button>
  );
};

export default WithUser<Header>(Header);

export const PageView = ({ children }: React.HTMLProps<HTMLElement>) => {
  return (
    <div className={styles.pageView}>
      <div className={styles.pageView__content}>{children}</div>
    </div>
  );
};
