import { isEmpty } from "lodash";
import { ParamsObj } from "core/utils";

const getURLWithParams = (URL: string, params: ParamsObj) => {
  if (isEmpty(params)) return URL;

  let arr = [];
  for (let key of Object.keys(params)) {
    if (params[key]) arr.push(`filters[${key}]=${params[key]}`);
  }

  if (!arr.length) return URL;

  return `${URL}?${arr.join("&")}`;
};

export default getURLWithParams;
