import { UserRoles } from "core/api/entities";

import {
  isStatusAnullato,
  isStatusMoreThanSospeso,
} from "core/orderStatusUtils";

const isOrderStatusEditable = (
  role: UserRoles,
  currentOrderSatusId: string
) => {
  if (isStatusAnullato(currentOrderSatusId)) {
    return false;
  }

  if (
    role === UserRoles.Retailer &&
    isStatusMoreThanSospeso(currentOrderSatusId)
  ) {
    return false;
  }

  return true;
};

export default isOrderStatusEditable;
