import Button from "components/Button";
import Modal, { OnCloseModaleCallbackCallback } from "components/Modal";
import StatusSelector from "components/StatusSelector";
import { ProductionOrderProduct, Status } from "core/api/entities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

let listener: any = null;

const setListener = function (callback: any) {
  listener = callback;
  return removeListener;
};

const removeListener = function () {
  listener = null;
};

interface OrderStatusModalProps {
  statuses: Status[];
  selectedProducts: ProductionOrderProduct[];
}

export type OnSelectStatusCallbackCallback = (selected: string) => void;

export const openChangeOrderStatusModal = ({
  statuses,
  selectedProducts,
}: OrderStatusModalProps): Promise<Status> =>
  new Promise((resolve, reject) => {
    listener(resolve, reject, statuses, selectedProducts);
  });

const ChangeOrderStatusModal = () => {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [originalSelectedStatus, setOriginalSelectedStatus] = useState<string>(
    ""
  );
  const [selectNewStatus, setSelectNewStatus] = useState<
    OnSelectStatusCallbackCallback
  >(() => {});
  const [closeModal, setCloseModal] = useState<OnCloseModaleCallbackCallback>(
    () => {}
  );

  const onUnmount = setListener(
    (
      resolve: any,
      reject: any,
      statuses: Status[],
      selectedProducts: ProductionOrderProduct[]
    ) => {
      setStatuses(statuses);
      setCounter(selectedProducts.length);
      setIsVisible(true);
      const currentSelectedStatus = Math.max(
        ...selectedProducts.map((b: ProductionOrderProduct) =>
          Number.parseInt(b.status.phaseId)
        )
      );
      setSelectedStatus(String(currentSelectedStatus));
      setOriginalSelectedStatus(String(currentSelectedStatus));
      const select = (selected: string) => {
        const newStatus = statuses.find((s) => s.phaseId === selected);
        setIsVisible(false);
        setSelectedStatus("");
        setCounter(0);
        resolve(newStatus);
      };
      const close = () => {
        setIsVisible(false);
        setSelectedStatus("");
        setCounter(0);
        reject("modale chiusa");
      };
      setCloseModal(() => close);
      setSelectNewStatus(() => select);
    }
  );

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  const onValueChange = (status: string): void => {
    setSelectedStatus(status);
  };

  return (
    <div>
      {isVisible && (
        <Modal
          title={t("change_order_status.title")}
          onCloseCallback={closeModal}
        >
          <div>
            <p>{t("change_order_status.help")}</p>
            <StatusSelector
              statuses={statuses}
              selectedStatus={selectedStatus}
              onStatusChange={onValueChange}
            ></StatusSelector>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.5rem",
              }}
            >
              <div>
                <Button
                  data-testid="confirm"
                  label={t("change_order_status.confirm")}
                  primary={true}
                  onClick={() => selectNewStatus(selectedStatus)}
                />{" "}
                <Button
                  data-testid="reset"
                  label={t("change_order_status.reset")}
                  onClick={() => setSelectedStatus(originalSelectedStatus)}
                />
              </div>
              <p>
                {counter}&nbsp;
                {counter > 1
                  ? t("change_order_status.selection_plural")
                  : t("change_order_status.selection_singular")}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChangeOrderStatusModal;
