import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Tbody, Tr, Td } from "components/Table";
import { Status, StatusEvent } from "core/api/entities";
import Section from "components/Section";
import OrderStatus from "components/OrderStatus";
import { isStatusAnullato } from "core/orderStatusUtils";
import toLocale from "core/toLocaleDateTime";

const iconStart = (
  <span className="material-icons vertical-align-middle">flag</span>
);
const iconDone = (
  <span className="material-icons vertical-align-middle">done_all</span>
);

const iconProgress = (
  <span className="material-icons vertical-align-middle">done</span>
);
const iconBack = (
  <span className="material-icons vertical-align-middle">undo</span>
);
const iconWatchLater = (
  <span className="material-icons vertical-align-middle">watch_later</span>
);

interface ProductionOrderItemHistoryProps {
  productHistory: StatusEvent[];
  statuses: Status[];
  currentStatus: Status;
}

const ProductionOrderItemHistory = ({
  productHistory,
  statuses,
  currentStatus,
}: ProductionOrderItemHistoryProps) => {
  const [t] = useTranslation();

  const getProductHistory = () => {
    if (productHistory.length === 0) {
      return (
        <Tr key={2}>
          <Td colSpan={2}>{t("orders.no_product_history")}</Td>
        </Tr>
      );
    }

    const reversedProductHistory = productHistory.sort(
      (statusEventA: StatusEvent, statusEventB: StatusEvent) => {
        if (statusEventA.change_date > statusEventB.change_date) return 1;
        if (statusEventA.change_date < statusEventB.change_date) return -1;
        return 0;
      }
    );

    return reversedProductHistory.map(
      (aStatusEvent: StatusEvent, index: number) => {
        const aStatus: Status =
          statuses.find((s) => s.phaseId === aStatusEvent.new_status_id) ||
          statuses[0];
        const eventDate = aStatusEvent.change_date;
        const isARegression =
          index > 0 &&
          Number.parseInt(aStatusEvent.new_status_id) <
            Number.parseInt(reversedProductHistory[index - 1].new_status_id);

        const getIcon = () => {
          if (aStatus.isFirst) return iconStart;
          if (aStatus.isLast) return iconDone;
          if (isARegression) return iconBack;
          return iconProgress;
        };

        return (
          <Tr key={`2-${index}`}>
            <Td
              style={{
                textAlign: "left",
                padding: "0.3rem 0.5rem 0.3rem 0rem",
              }}
            >
              {getIcon()}&nbsp;
              {toLocale(eventDate)}
            </Td>
            <Td style={{ textAlign: "left", padding: "0.3rem 0.5rem" }}>
              <OrderStatus status={aStatus} />
            </Td>
          </Tr>
        );
      }
    );
  };

  const getProductNextSteps = () => {
    if (isStatusAnullato(currentStatus.phaseId)) {
      return;
    }

    const filterdStatuses =
      productHistory.length > 0
        ? statuses.filter(
            (aStatus: Status) =>
              Number.parseInt(aStatus.phaseId) >
              Number.parseInt(
                productHistory[productHistory.length - 1].new_status_id
              )
          )
        : statuses;

    return filterdStatuses.map((aStatus: Status, index: number) => {
      return (
        <Tr key={`1-${index}`} style={{ opacity: 0.7 }}>
          <Td
            style={{ textAlign: "left", padding: "0.3rem 0.5rem 0.3rem 0rem" }}
          >
            {iconWatchLater}
            &nbsp;
            {t("orders.todo")}
          </Td>
          <Td style={{ textAlign: "left", padding: "0.3rem 0.5rem" }}>
            <OrderStatus status={aStatus} />
          </Td>
        </Tr>
      );
    });
  };

  return (
    <Section>
      <dt>{t("orders.product_history")}:</dt>
      <Table>
        <Tbody>
          {getProductHistory()}
          {getProductNextSteps()}
        </Tbody>
      </Table>
    </Section>
  );
};

export default ProductionOrderItemHistory;
