import {
  ProductionOrderProduct,
  Status,
  ProductionOrder,
} from "core/api/entities";

import { Eq, eqString, contramap } from "fp-ts/Eq";
import { Lens } from "monocle-ts";

type Predicate<A> = (a: A) => boolean;
type Mapper<A> = (a: A) => A;

const uuid = Lens.fromProp<ProductionOrderProduct>()("orderDetailUUID");
const status = Lens.fromProp<ProductionOrderProduct>()("status");
const prodcuts = Lens.fromProp<ProductionOrder>()("products");

const sameProdcut: Eq<ProductionOrderProduct> = contramap(uuid.get)(eqString);

function equalsTo<A>(base: A, eq: Eq<A>): Predicate<A> {
  return (other) => eq.equals(base, other);
}

function mapIf<A>(predicate: Predicate<A>, mapper: Mapper<A>): Mapper<A> {
  return (base) => {
    if (predicate(base)) {
      return mapper(base);
    }

    return base;
  };
}

function mapSameProduct(
  product: ProductionOrderProduct,
  setter: Mapper<ProductionOrderProduct>
): (order: ProductionOrder) => ProductionOrder {
  const equalsToProduct = equalsTo(product, sameProdcut);
  const productMapper = mapIf(equalsToProduct, setter);
  return (order) => {
    const newProducts = order.products.map(productMapper);
    return prodcuts.set(newProducts)(order);
  };
}

function setNewStatus(
  newStatus: Status
): (
  orderList: ProductionOrder[],
  product: ProductionOrderProduct
) => ProductionOrder[] {
  return (orderList, product) =>
    orderList.map(mapSameProduct(product, status.set(newStatus)));
}

export const changeStatusOnProducts = (
  orderList: ProductionOrder[],
  products: ProductionOrderProduct[],
  newStatus: Status
): ProductionOrder[] => {
  return products.reduce<ProductionOrder[]>(setNewStatus(newStatus), orderList);
};

export default {
  changeStatusOnProducts,
};
