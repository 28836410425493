import React, { useEffect, useState } from "react";
import currentLanguage, { ChangeCallback } from "core/currentLanguage";

export interface LanguageProps {
  changeLanguage: ChangeCallback;
  language: string;
}

export default function withLanguage<P>(
  Component: React.ComponentType<P & LanguageProps>
): React.ComponentType<P> {
  return (props: P) => {
    const [language, setLanguage] = useState<string>(currentLanguage.get());

    useEffect(() => {
      setLanguage(currentLanguage.get());
      const unsub = currentLanguage.subscribe(setLanguage);
      return unsub;
    }, []);

    return (
      <Component
        {...props}
        changeLanguage={currentLanguage.set}
        language={language}
      />
    );
  };
}
