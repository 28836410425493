import React from "react";
import { WeeklyTimetable } from "core/api/entities";
import Button from "components/Button";
import TimetableWeek from "pages/timetable/components/TimetableWeek";
import Grid from "components/Grid";
import { useTranslation } from "react-i18next";

interface WeeklyTimetableProps {
  weeklyTimetables: WeeklyTimetable[];
}

const WeeklyTimetablesComponent = ({
  weeklyTimetables,
}: WeeklyTimetableProps) => {
  const [t] = useTranslation();
  return (
    <div>
      <Grid col={12} style={{ textAlign: "right" }}>
        <Button
          icon="print"
          primary={true}
          onClick={() => window.print()}
          label={t("print")}
        />
      </Grid>
      {weeklyTimetables.map((aTimetable: WeeklyTimetable, index: number) => (
        <TimetableWeek key={index} timetable={aTimetable} />
      ))}
    </div>
  );
};

export default WeeklyTimetablesComponent;
