import fromUnixTime from "date-fns/fromUnixTime";

const toLocale = (date: number): string => {
  return fromUnixTime(date).toLocaleDateString("it-IT", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export default toLocale;
