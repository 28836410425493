import React from "react";
import WithLanguage, { LanguageProps } from "components/hoc/WithLanguage";
interface PriceProps {
  value: number;
  currency: string;
}

export const priceFormatter = (
  aNumber: number,
  aLanguage: string,
  aCurrency: string
) => {
  return new Intl.NumberFormat(aLanguage, {
    style: "currency",
    currency: aCurrency,
  }).format(aNumber);
};

export const Price = (props: PriceProps & LanguageProps) => {
  const { value, language, currency } = props;
  return <span>{priceFormatter(value, language, currency)}</span>;
};

export default WithLanguage<PriceProps>(Price);
