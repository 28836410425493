import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ProductionOrderItemSummary from "pages/orders/components/ProductionOrderItemSummary";
import ProductionOrderItemHistory from "pages/orders/components/ProductionOrderItemHistory";
import ProductionOrderItemOptions from "pages/orders/components/ProductionOrderItemOptions";
import {
  ProductionOrderProduct,
  ProductionOrder,
  Status,
  StatusEvent,
  UserRoles,
} from "core/api/entities";
import Grid from "components/Grid";
import { OnCloseModaleCallbackCallback } from "components/Modal";
import styles from "pages/orders/components/ProductionOrderItemModal.module.scss";
import WithUser, { UserProps } from "components/hoc/WithUser";

import TrackingInfo from "pages/orders/components/TrackingInfo";

let listener: any = null;

const setListener = function (callback: any) {
  listener = callback;
  return removeListener;
};

const removeListener = function () {
  listener = null;
};
interface openProductionOrderItemModalProps {
  product: ProductionOrderProduct;
  order: ProductionOrder;
  productHistory: StatusEvent[];
  statuses: Status[];
}

export const openProductionOrderItemModal = ({
  product,
  order,
  productHistory,
  statuses,
}: openProductionOrderItemModalProps) =>
  new Promise((resolve, reject) => {
    listener(product, order, productHistory, statuses, resolve, reject);
  });

interface ProductionOrderItemModalProps {}

export const ProductionOrderItemModal = ({
  user,
}: ProductionOrderItemModalProps & UserProps) => {
  const [product, setProduct] = useState<ProductionOrderProduct>();
  const [order, setOrder] = useState<ProductionOrder>();
  const [productHistory, setProductHistory] = useState<StatusEvent[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<OnCloseModaleCallbackCallback>(
    () => {}
  );

  const onUnmount = setListener(
    (
      aProduct: ProductionOrderProduct,
      aOrder: ProductionOrder,
      productHistory: StatusEvent[],
      statuses: Status[],
      resolve: any,
      _: any
    ) => {
      setProduct(aProduct);
      setOrder(aOrder);
      setProductHistory(productHistory);
      setStatuses(statuses);
      setIsVisible(true);
      const close = () => {
        setIsVisible(false);
        resolve("modal closed");
      };
      setCloseModal(() => close);
    }
  );

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  return (
    <div>
      {isVisible && product && order && (
        <Modal title={product.name} onCloseCallback={closeModal}>
          <Grid col={6} pr={1}>
            <img
              src={product.thumbnailUrl}
              alt={product.name}
              className={styles.productimage}
            />
            <ProductionOrderItemOptions product={product} />
          </Grid>
          <Grid col={6} pl={1}>
            <TrackingInfo
              product={product}
              onTrackingInfoSaved={(newProduct) => setProduct(newProduct)}
            />

            <ProductionOrderItemSummary
              user={user}
              product={product}
              order={order}
            />

            {user?.role !== UserRoles.Retailer ? (
              <ProductionOrderItemHistory
                statuses={statuses}
                productHistory={productHistory}
                currentStatus={product.status}
              />
            ) : (
              <span></span>
            )}
          </Grid>
        </Modal>
      )}
    </div>
  );
};

export default WithUser<ProductionOrderItemModalProps>(
  ProductionOrderItemModal
);
