import fromUnixTime from "date-fns/fromUnixTime";

const toLocaleDay = (date: number): string => {
  return `${fromUnixTime(date).toLocaleDateString("it-IT", {
    month: "numeric",
    day: "numeric",
  })}`;
};

export default toLocaleDay;
