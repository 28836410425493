import React, { useEffect, useState } from "react";
import PageWrapper from "components/PageWrapper";
import toastDelegate from "core/toastDelegate";
import orders from "core/api/orders";
import mainBlockingLoadingDelegate from "core/mainBlockingLoadingDelegate";
import { ToastStati, WeeklyTimetable } from "core/api/entities";
import WeeklyTimetablesComponent from "pages/timetable/components/WeeklyTimetablesComponent";

const Timetable = () => {
  const [weeklyTimetables, setWeeklyTimetables] = useState<WeeklyTimetable[]>(
    []
  );

  useEffect(() => {
    mainBlockingLoadingDelegate.start();

    orders
      .timetable()
      .then((response: WeeklyTimetable[]) => {
        setWeeklyTimetables(response);
      })
      .catch((e) => {
        toastDelegate.add({
          message: e.message,
          toastStatus: ToastStati.Warning,
        });
      })
      .finally(() => {
        mainBlockingLoadingDelegate.stop();
      });
  }, []);

  return (
    <PageWrapper>
      <WeeklyTimetablesComponent weeklyTimetables={weeklyTimetables} />
    </PageWrapper>
  );
};

export default Timetable;
