import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionOrderListItemComponent from "./ProductionOrderListItem";
import ProductionOrderListEmptyItem from "./ProductionOrderListEmptyItem";
import ProductionOrderListItemHeaderComponent from "./ProductionOrderListItemHeader";
import { ProductionOrder, ProductionOrderProduct } from "core/api/entities";
import { Table, Thead, Tbody } from "components/Table";
import ToastComponent from "components/Toast";
import { ButtonColors } from "components/Button";
import getRequirements from "../services/getRequirementsService";

export type OpenProductionOrderListCallback = (
  product: ProductionOrderProduct,
  order: ProductionOrder
) => void;
export type ProductionOrderSelectionCallback = (
  product: ProductionOrderProduct
) => void;
export type ProductionOrderListProps = {
  data: ProductionOrder[];
  onChangeStatus: (products: ProductionOrderProduct[]) => Promise<void>;
  onProductOpen?: OpenProductionOrderListCallback;
};

const ProductionOrderList = ({
  data,
  onChangeStatus,
  onProductOpen,
}: ProductionOrderListProps) => {
  const [t] = useTranslation();
  const [checkboxSelection, setCheckboxSelection] = useState<
    ProductionOrderProduct[]
  >([]);

  const onChangeSelection = (product: ProductionOrderProduct) => {
    if (isProductionOrderListItemChecked(product)) {
      setCheckboxSelection([
        ...checkboxSelection.filter(
          (p) => p.orderDetailUUID !== product.orderDetailUUID
        ),
      ]);
    } else {
      setCheckboxSelection([...checkboxSelection.concat([product])]);
    }
  };

  const isProductionOrderListItemChecked = (
    product: ProductionOrderProduct
  ) => {
    return checkboxSelection.some(
      (p) => p.orderDetailUUID === product.orderDetailUUID
    );
  };

  const cancelSelection = () => {
    setCheckboxSelection([]);
  };

  const confirmSelection = () => {
    onChangeStatus(checkboxSelection);
    setCheckboxSelection([]);
  };

  const printRequirements = useCallback(async () => {
    await getRequirements(checkboxSelection);
    setCheckboxSelection([]);
  }, [checkboxSelection]);

  const getMappeDataRow = (notMappedData: ProductionOrder[]): JSX.Element[] => {
    let elements: JSX.Element[] = [];

    if (notMappedData.length === 0)
      return [<ProductionOrderListEmptyItem key={1} />];

    notMappedData.forEach((dataRow: ProductionOrder, orderRowIndex: number) =>
      dataRow.products.forEach(
        (productRow: ProductionOrderProduct, productRowIndex: number) => {
          elements.push(
            <ProductionOrderListItemComponent
              onChangeStatus={onChangeStatus}
              onProductSelectedByCheckbox={onChangeSelection}
              key={`${dataRow.orderExternalId}-${productRowIndex}`}
              idChecked={isProductionOrderListItemChecked(productRow)}
              dataRow={dataRow}
              orderId={dataRow.orderExternalId}
              isOdd={orderRowIndex % 2 !== 0}
              productRow={productRow}
              productRowIndex={productRowIndex}
              onProductOpen={onProductOpen}
            />
          );
        }
      )
    );

    return elements;
  };

  return (
    <div>
      <Table>
        <Thead>
          <ProductionOrderListItemHeaderComponent />
        </Thead>
        <Tbody>{getMappeDataRow(data)}</Tbody>
      </Table>
      {checkboxSelection.length ? (
        <ToastComponent
          index={0}
          message={(() => {
            const text =
              checkboxSelection.length > 1
                ? t("change_order_status.selection_plural")
                : t("change_order_status.selection_singular");
            return `${checkboxSelection.length} ${text}`;
          })()}
          onDismissClicked={cancelSelection}
          buttons={[
            {
              label: t("orders.change_status"),
              onClick: confirmSelection,
              small: true,
              disabled: false,
              buttonTheme: ButtonColors.ColorInfo,
            },
            {
              label: t("orders.print_requirements"),
              onClick: printRequirements,
              small: true,
              disabled: false,
              buttonTheme: ButtonColors.ColorInfo,
            },
            {
              label: t("orders.cancel_selection"),
              onClick: cancelSelection,
              small: true,
              disabled: false,
              primary: true,
              buttonTheme: ButtonColors.ColorInfo,
            },
          ]}
        />
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default ProductionOrderList;
