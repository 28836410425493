import restCall from "./restCall";
import { User } from "core/api/entities";
const { REACT_APP_BASE_URL } = process.env;

const CACHE_KEY = "user";

const cached = (): User | undefined => {
  const value = sessionStorage.getItem(CACHE_KEY);
  if (value != null) {
    return JSON.parse(value);
  }
  return undefined;
};

const clearCache = () => sessionStorage.removeItem(CACHE_KEY);

const get = (): Promise<User> => {
  const maybeUser = cached();

  if (maybeUser) {
    return Promise.resolve(maybeUser);
  }

  return restCall(`${REACT_APP_BASE_URL}/user`)
    .then((r) => r.json())
    .then((user) => {
      sessionStorage.setItem(CACHE_KEY, JSON.stringify(user));
      return user;
    });
};

export default {
  get,
  clearCache,
};
