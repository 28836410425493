import React, { useEffect, useState } from "react";
import { ProductCategory } from "core/api/entities";
import categoriesAPI from "core/api/categories";
import { ToastStati } from "core/api/entities";
import mainBlockingLoadingDelegate from "core/mainBlockingLoadingDelegate";
import toastDelegate from "core/toastDelegate";
import PageWrapper from "components/PageWrapper";
import Orders from "pages/orders/Orders";
import { Tabs, TabsItem } from "components/Tabs";
import WithLanguage, { LanguageProps } from "components/hoc/WithLanguage";

type SelectedCategoryType = ProductCategory | null;

const OrdersPageWrapper = (props: LanguageProps) => {
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<
    SelectedCategoryType
  >(null);

  useEffect(() => {
    mainBlockingLoadingDelegate.start();

    categoriesAPI
      .list()
      .then((r) => {
        setCategories(r);
        if (r.length) {
          setSelectedCategory(r[0]);
        }
      })
      .catch((e) => {
        toastDelegate.add({
          message: e.message,
          toastStatus: ToastStati.Warning,
        });
      })
      .finally(() => {
        mainBlockingLoadingDelegate.stop();
      });
  }, [props.language]);

  const isCategoryTabsVisible = categories.length > 1;
  const isCategorySelected = (cat: ProductCategory) =>
    cat.slug === selectedCategory?.slug;

  return (
    <PageWrapper>
      {isCategoryTabsVisible ? (
        <Tabs>
          {categories.map((c) => (
            <TabsItem
              isActive={isCategorySelected(c)}
              onClick={() => setSelectedCategory(c)}
              key={c.slug}
              label={c.label}
            />
          ))}
        </Tabs>
      ) : (
        <span></span>
      )}
      {!selectedCategory ? (
        <span></span>
      ) : (
        <Orders selectedCategory={selectedCategory} />
      )}
    </PageWrapper>
  );
};

export default WithLanguage(OrdersPageWrapper);
