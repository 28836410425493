const createLink = (url: string, filename: string): HTMLAnchorElement => {
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  return a;
};

function download(blob: Blob, filname: string) {
  const url = window.URL.createObjectURL(blob);
  const a = createLink(url, filname);
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

export default download;
