import React from "react";
import styles from "components//Table.module.scss";
import getClassNames from "core/getClassNames";
import { StyleClasses } from "core/utils.d";
import { get } from "lodash";

interface Table extends React.TableHTMLAttributes<HTMLTableElement> {
  options?: object;
}

export const Table = ({ children, options }: Table) => {
  let tableClasses: StyleClasses = {
    [styles.root]: true,
    [styles.root__modifierFixed]: get(options, "isFixed", false),
  };
  const tableClassNames = getClassNames(tableClasses);
  return <table className={tableClassNames}>{children}</table>;
};

interface Tr extends React.TableHTMLAttributes<HTMLTableRowElement> {
  isOdd?: boolean;
}
export const Tr = (props: Tr) => {
  const { children, isOdd, style } = props;
  let trClasses: StyleClasses = {
    [styles.root__tr]: true,
    [styles.root__tr_modifierOdd]: !!isOdd,
  };
  const trClassNames = getClassNames(trClasses);
  return (
    <tr style={style} className={trClassNames}>
      {children}
    </tr>
  );
};

interface TdProps {
  options?: object;
  colSpan?: number; // WHY?
  rowSpan?: number; // WHY?
}
export const Td = (
  props: TdProps & React.TableHTMLAttributes<HTMLTableCellElement>
) => {
  const { children, options } = props;
  let tdClasses: StyleClasses = {
    [styles.root__td]: true,
    [styles.root__td_modifierTextAlignLeft]: get(
      options,
      "isTextAlignLeft",
      false
    ),
  };
  const tdClassNames = getClassNames(tdClasses);
  return (
    <td className={tdClassNames} {...props}>
      {children}
    </td>
  );
};

interface ThProps
  extends React.TableHTMLAttributes<HTMLTableHeaderCellElement> {
  options?: object;
}

export const Th = (props: ThProps) => {
  const { children, options } = props;
  let thClasses: StyleClasses = {
    [styles.root__th]: true,
    [styles.root__th_modifierBorderLight]: get(options, "isBorderLight", false),
    [styles.root__th_modifierTextAlignLeft]: get(
      options,
      "isTextAlignLeft",
      false
    ),
  };
  const thClassNames = getClassNames(thClasses);
  return (
    <th className={thClassNames} {...props}>
      {children}
    </th>
  );
};

export const Thead = ({
  children,
}: React.TableHTMLAttributes<HTMLTableSectionElement>) => {
  return <thead>{children}</thead>;
};

export const Tbody = ({
  children,
}: React.TableHTMLAttributes<HTMLTableSectionElement>) => {
  return <tbody>{children}</tbody>;
};
