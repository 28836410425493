import { getToken } from "./auth";
import http, { Config, HttpCall } from "./http";
import currentLanguage from "core/currentLanguage";

const setHeaders = (
  base: Record<string, string> = {},
  token: string,
  language: string
): Record<string, string> => {
  return {
    "Accept-Language": language,
    Authorization: token,
    ...base,
  };
};

const request: HttpCall = async (
  url: string,
  config: Config = {}
): Promise<any> => {
  const token = await getToken();
  const language = currentLanguage.get();
  config.headers = setHeaders(config.headers, token ?? "", language);
  return http(url, config);
};

export default request;
