import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ToastStati,
  ProductionOrderProduct,
  UserRoles,
} from "core/api/entities";
import toastDelegate from "core/toastDelegate";
import asyncLoadingDelegate from "core/asyncLoadingDelegate";
import orders from "core/api/orders";
import Button from "components/Button";
import ToLink from "components/ToLink";
import styles from "pages/orders/components/TrackingInfo.module.scss";
import WithUser, { UserProps } from "components/hoc/WithUser";

interface TrackingInfoProps {
  product: ProductionOrderProduct;
  onTrackingInfoSaved: (newProduct: ProductionOrderProduct) => void;
}

const TrackingInfo = ({
  product,
  onTrackingInfoSaved,
  user,
}: TrackingInfoProps & UserProps) => {
  const [t] = useTranslation();

  const [trackingInfo, setTrackingInfo] = useState<string>(
    product.trackingInfo
  );
  const [isInEditing, setIsInEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const saveTrackingInfo = async (trackingInfo: string) => {
    if (!product) return;
    asyncLoadingDelegate.start();
    setLoading(true);
    try {
      await orders.changeTrackingInfo(product, trackingInfo);
      onTrackingInfoSaved({ ...product, trackingInfo });
      toastDelegate.add({
        message: t("toast_generics.success"),
        toastStatus: ToastStati.Success,
      });
    } catch (e) {
      toastDelegate.add({
        message: e.message,
        toastStatus: ToastStati.Warning,
      });
    } finally {
      asyncLoadingDelegate.stop();
      setIsInEditing(false);
      setLoading(false);
    }
  };

  const toggleEditing = () => {
    if (isInEditing) setTrackingInfo(product.trackingInfo);
    setIsInEditing(!isInEditing);
  };

  return (
    <div className={styles.root}>
      <label className={styles.root__label}>{t("orders.tracking_info")}</label>
      {isInEditing ? (
        <input
          className={styles.root__input}
          type="text"
          disabled={loading}
          value={trackingInfo}
          onChange={(e) => setTrackingInfo(e.target.value)}
        />
      ) : (
        <dd className={styles.root__info}>
          <ToLink>{trackingInfo || t("orders.na")}</ToLink>
        </dd>
      )}
      {isInEditing && (
        <div className={styles.root__actionWrapper}>
          <Button
            small={true}
            label={t("save")}
            disabled={trackingInfo === product.trackingInfo || loading}
            onClick={() => saveTrackingInfo(trackingInfo)}
          />
        </div>
      )}
      {user?.role === UserRoles.Pm && (
        <div className={styles.root__actionWrapper}>
          <Button
            small={true}
            disabled={loading}
            label={isInEditing ? t("cancel") : t("edit")}
            onClick={toggleEditing}
          />
        </div>
      )}
    </div>
  );
};

export default WithUser<TrackingInfoProps>(TrackingInfo);
