import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingMessageStatusProps } from "core/asyncLoadingDelegate";
import styles from "components/AsyncLoading.module.scss";

const AsyncLoading = ({ message, isLoading }: LoadingMessageStatusProps) => {
  const [t] = useTranslation();
  if (!isLoading) return <span></span>;

  return <div className={styles.root}>{message || t("loading")}</div>;
};

export default AsyncLoading;
