import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import Amplify from "aws-amplify";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import it from "./translations/it.json";
import en from "./translations/en.json";
import currentLanguage from "core/currentLanguage";

import "styles/shared.scss";

const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
} = process.env;

Amplify.configure({
  Auth: {
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

i18n.use(initReactI18next).init({
  resources: {
    it: {
      translation: it,
    },
    en: {
      translation: en,
    },
  },
  lng: currentLanguage.get(),
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

currentLanguage.subscribe((language) => {
  i18n.changeLanguage(language);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
