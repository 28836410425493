import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Orders from "./pages/orders/OrdersPageWrapper";
import Timetable from "pages/timetable/Timetable";
import About from "./pages/About";
import ToastContainer from "components/ToastContainer";
import users from "core/api/user";
import Loading from "components/Loading";
import currentLanguage from "core/currentLanguage";
import currentUser from "core/currentUser";
import { User } from "core/api/entities";
import OrderDetail from "pages/detail/OrderDetail";

function App(props: any) {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    users.get().then((u) => {
      currentLanguage.set(u.language);
      currentUser.set(u);
      setUser(u);
    });
  }, []);

  if (!user) {
    return <Loading isLoading={true}></Loading>;
  }

  return (
    <ToastContainer>
      <Router>
        <Switch>
          <Route exact path="/">
            <Orders />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/timetable">
            <Timetable />
          </Route>
          <Route path="/orders/detail/:id">
            <OrderDetail />
          </Route>
        </Switch>
      </Router>
    </ToastContainer>
  );
}

export default withAuthenticator(App);
