import React from "react";
import { TimetableOrderProduct } from "core/api/entities";
import OrderStatus from "components/OrderStatus";
import styles from "pages/timetable/components/TimetableOrderProduct.module.scss";
import TimetableOrderProcessDateMissingDays from "pages/timetable/components/TimetableOrderMissingDays";
interface TimetableOrderProductProps {
  timetableOrderProduct: TimetableOrderProduct;
}

const TimetableOrderProductComponent = ({
  timetableOrderProduct,
}: TimetableOrderProductProps) => {
  return (
    <span className={styles.root}>
      <span className={styles.root__main}>
        {timetableOrderProduct.id} / {timetableOrderProduct.sequence}{" "}
        {timetableOrderProduct.description}
      </span>
      <span className={styles.root__meta}>
        <OrderStatus status={timetableOrderProduct.status} />
      </span>
      <span className={styles.root__dates}>
        <TimetableOrderProcessDateMissingDays
          currentDate={new Date().getTime()}
          processDate={timetableOrderProduct.processDate}
        />
      </span>
    </span>
  );
};

export default TimetableOrderProductComponent;
