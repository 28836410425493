import React from "react";
import styles from "components//Modal.module.scss";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

export type OnCloseModaleCallbackCallback = () => void;
type ModalProps = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  onCloseCallback?: OnCloseModaleCallbackCallback;
};

const Modal = ({ children, title, onCloseCallback }: ModalProps) => {
  const [t] = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.root__backdrop} onClick={onCloseCallback}></div>
      <div className={styles.root__dialog}>
        <div className={styles.layout}>
          <div className={styles.layout__header}>
            <h2>{title}</h2>
            <Button label={t("close")} onClick={onCloseCallback} />
          </div>
          <div className={styles.layout__body}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
