import React from "react";
import styles from "components//Button.module.scss";
import { StyleClasses } from "core/utils.d";
import getClassNames from "core/getClassNames";

export enum ButtonColors {
  ColorStandard = "ColorStandard",
  ColorPrimary = "ColorPrimary",
  ColorSecondary = "ColorSecondary",
  ColorSuccess = "ColorSuccess",
  ColorInfo = "ColorInfo",
  ColorWarning = "ColorWarning",
  ColorDanger = "ColorDanger",
  ColorNeutral = "ColorNeutral",
}
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  primary?: boolean;
  icon?: string;
  small?: boolean;
  buttonTheme?: ButtonColors;
}

const Button = ({
  label,
  primary = false,
  small = false,
  buttonTheme,
  icon,
  ...props
}: ButtonProps) => {
  let buttonClasses: StyleClasses = {
    [styles.root]: true,
    [styles.root_modifierSmall]: small,
    [styles.root_modifierPrimary]: primary,
    [styles.root_modifierColorPrimary]:
      buttonTheme === ButtonColors.ColorPrimary,
    [styles.root_modifierColorSecondary]:
      buttonTheme === ButtonColors.ColorSecondary,
    [styles.root_modifierColorSuccess]:
      buttonTheme === ButtonColors.ColorSuccess,
    [styles.root_modifierColorInfo]: buttonTheme === ButtonColors.ColorInfo,
    [styles.root_modifierColorWarning]:
      buttonTheme === ButtonColors.ColorWarning,
    [styles.root_modifierColorDanger]: buttonTheme === ButtonColors.ColorDanger,
    [styles.root_modifierColorNeutral]:
      buttonTheme === ButtonColors.ColorNeutral,
  };

  const buttonClassNames = getClassNames(buttonClasses);

  return (
    <button {...props} className={buttonClassNames}>
      {icon ? (
        <span className={`material-icons ${styles.root__icon}`}>{icon}</span>
      ) : (
        <span></span>
      )}
      {label}
    </button>
  );
};

Button.defaultProps = {
  buttonTheme: ButtonColors.ColorStandard,
};

export default Button;
