import React from "react";
import { useTranslation } from "react-i18next";
import { Tr, Td } from "components//Table";
import WithUser, { UserProps } from "components/hoc/WithUser";
import { UserRoles } from "core/api/entities";

export type ProductionOrderListEmptyItemProps = {};

interface mapRoleToColSpanProps {
  [key: string]: number;
}

const ProductionOrderListEmptyItemComponent = ({
  user,
}: ProductionOrderListEmptyItemProps & UserProps) => {
  const [t] = useTranslation();

  const userRole = user ? user.role : UserRoles.Retailer;

  const mapRoleToColSpan: mapRoleToColSpanProps = {
    pm: 11,
    retailer: 12,
    brand: 14,
  };

  return (
    <Tr isOdd={false}>
      <Td colSpan={mapRoleToColSpan[userRole]}>
        <p>{t("no_results")}</p>
      </Td>
    </Tr>
  );
};

export default WithUser<ProductionOrderListEmptyItemProps>(
  ProductionOrderListEmptyItemComponent
);
