export enum ToastStati {
  Success,
  Danger,
  Warning,
  Info,
}

export type Toast = {
  message: string;
  toastStatus: ToastStati;
  birth: number;
};

export interface ToastLike {
  message: string;
  toastStatus: ToastStati;
}

export type OrderListResponse = {
  pagination: Pagination;
  orders: ProductionOrder[];
};
export interface TimetableOrderProduct {
  status: Status;
  id: number;
  sequence: number;
  processDate: number;
  description: string;
}
export interface DailyTimetable {
  dayId: string;
  date: number;
  orderProducts: TimetableOrderProduct[];
}
export interface WeeklyTimetable {
  weekId: string;
  days: DailyTimetable[];
}

export type Pagination = {
  pageCount: number;
  start: number;
  totalCount: number;
  nextUrl: string;
  previousUrl: string;
};

export type Status = {
  phaseId: string;
  label: string;
  isFirst: boolean;
  isLast: boolean;
};

export type StatusEvent = {
  change_date: number;
  new_status_id: string;
  username: string;
};

export type ProductCategory = {
  slug: string;
  label: string;
  statuses: Status[];
};

export type ProductionOrderInfoEngraving = {
  name: string;
  text: string;
};

type ProductionOrderInfoVariant = {
  name: string;
  engraving: ProductionOrderInfoEngraving[];
};

type ProductionOrderInfo = {
  variants: ProductionOrderInfoVariant[];
};

export type ProductionOrderProduct = {
  orderDetailUUID: string;
  status: Status;
  price: number;
  quantity: number;
  id: number;
  factory: string;
  thumbnailUrl: string;
  name: string;
  category: string;
  processDate: number;
  note: string;
  description: string;
  details?: ProductionOrderProductDetail[];
  info?: ProductionOrderInfo;
  trackingInfo: string;
  orderCode: string;
  numberOfOrderDetails: number;
  internalSequence: number;
};

export type ProductionOrder = {
  orderExternalId: string;
  orderChannel: string;
  company: string;
  brand: string;
  dateCreation: number;
  amount: number;
  currency: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  notes: string;
  products: ProductionOrderProduct[];
};

export type ProductionOrderProductDetail = {
  attribute: string;
  value: string;
};

export interface User {
  readonly username: string;
  readonly language: string;
  readonly email: string;
  readonly role: UserRoles;
}

export enum UserRoles {
  Pm = "pm",
  Retailer = "retailer",
  Brand = "brand",
}
