import { User } from "core/api/entities";

type ChangeCallback = (user: User) => void;
type Subscribe = (cb: ChangeCallback) => () => void;
export type CurrentUser = User | null;

const DEFAULT_USER = null;

let currentUser: CurrentUser = DEFAULT_USER;
let changeListeners: ChangeCallback[] = [];

const set = (u: User): void => {
  currentUser = u;
};

const get = (): CurrentUser => currentUser;

const remove = (u: User): void => {
  currentUser = DEFAULT_USER;
};

const isCurrentUser = (): boolean => {
  return !!currentUser;
};

const subscribe: Subscribe = (cb: ChangeCallback) => {
  changeListeners.push(cb);
  return () => {
    changeListeners = changeListeners.filter((t) => t !== cb);
  };
};

export default {
  set,
  get,
  isCurrentUser,
  subscribe,
  remove,
};
