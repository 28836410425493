import React from "react";
import { useTranslation } from "react-i18next";
import { CurrentUser } from "core/currentUser";
import { UserRoles } from "core/api/entities";
import { ProductionOrderProduct, ProductionOrder } from "core/api/entities";
import ProductionOrderProcessDateMissingDays from "./ProductionOrderMissingDays";
import Section from "components/Section";
import OrderStatus from "components/OrderStatus";
import Grid from "components/Grid";
import PriceComponent from "components/Price";

interface ProductionOrderItemSummaryProps {
  user?: CurrentUser;
  product: ProductionOrderProduct;
  order: ProductionOrder;
}

const ProductionOrderItemSummary = ({
  product,
  user,
  order,
}: ProductionOrderItemSummaryProps) => {
  const [t] = useTranslation();

  return (
    <div>
      <Grid col={6} pr={1}>
        {product.description ? (
          <Section>
            <dt>{t("orders.product")}:</dt>
            <dd>
              {product.name}, {product.description}
            </dd>
          </Section>
        ) : (
          <span></span>
        )}
        <Section>
          <dt>{t("orders.quantity")}</dt>
          <dd>{product.quantity}X</dd>
        </Section>
        <Section>
          <dt>{t("orders.category")}:</dt>
          <dd>{product.category}</dd>
        </Section>
        <Section>
          <dt>{t("orders.scheduled_delivery")}:</dt>
          <ProductionOrderProcessDateMissingDays
            processDate={product.processDate}
            currentDate={new Date().getTime()}
          />
        </Section>
        <Section>
          <dt>{t("orders.note")}:</dt>
          <dd>{order.notes || t("orders.na")}</dd>
        </Section>
      </Grid>
      <Grid col={6} pl={1}>
        {user?.role !== UserRoles.Pm ? (
          <Section>
            <dt>{t("customer")}:</dt>
            <dd>
              {order.email}
              <div>
                {order.firstName} {order.lastName}
              </div>
              <div>{order.phone}</div>
            </dd>
          </Section>
        ) : (
          <span></span>
        )}
        <Section>
          <dt>{t("orders.status")}:</dt>
          <OrderStatus status={product.status} />
        </Section>
        <Section>
          <dt>{t("orders.factory")}:</dt>
          <dd>{product.factory}</dd>
        </Section>
        {user?.role !== UserRoles.Pm ? (
          <Section>
            <dt>{t("orders.price")}:</dt>
            <dd>
              <PriceComponent value={product.price} currency={order.currency} />
            </dd>
          </Section>
        ) : (
          <span></span>
        )}
        {user?.role !== UserRoles.Retailer ? (
          <Section>
            <dt>{t("orders.channel")}:</dt>
            <dd>{order.orderChannel}</dd>
          </Section>
        ) : (
          <span></span>
        )}
      </Grid>
    </div>
  );
};

export default ProductionOrderItemSummary;
