import withUser, { UserProps } from "components/hoc/WithUser";
import OrderStatus from "components/OrderStatus";
import { Status, UserRoles } from "core/api/entities";
import getClassNames from "core/getClassNames";
import isStatusSelectable from "core/isStatusSelectable";
import { isStatusAnullato } from "core/orderStatusUtils";
import { StyleClasses } from "core/utils.d";
import styles from "pages/orders/components/ChangeOrderStatusModal.module.scss";
import React, { useCallback } from "react";

interface StatusSelectorProps {
  statuses: Status[];
  selectedStatus: string;
  onStatusChange: (status: string) => void;
}

function StatusSelector(props: StatusSelectorProps & UserProps) {
  const { statuses, selectedStatus, onStatusChange, user } = props;

  const userRole = user ? user.role : UserRoles.Retailer;

  const onClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onStatusChange(event.target.value);
    },
    [onStatusChange]
  );

  return (
    <form className={styles.selectionContainer}>
      {statuses.map((s) => {
        const statusClasses: StyleClasses = {
          [styles.selectionContainer__item]: true,
          [styles.selectionContainer__item_modifierFirst]: s.isFirst,
          [styles.selectionContainer__item_modifierLast]: s.isLast,
          [styles.selectionContainer__item_modifierCanceled]: isStatusAnullato(
            s.phaseId
          ),
        };
        const statusClassNames = getClassNames(statusClasses);
        return (
          <label key={s.phaseId} className={statusClassNames}>
            <OrderStatus status={s} />
            <input
              type="radio"
              disabled={
                !isStatusSelectable(userRole, s.phaseId, selectedStatus)
              }
              value={s.phaseId}
              onChange={onClick}
              checked={s.phaseId === selectedStatus}
            />
          </label>
        );
      })}
    </form>
  );
}

export default withUser<StatusSelectorProps>(StatusSelector);
