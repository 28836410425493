import React from "react";
import styles from "components/Tabs.module.scss";
import { StyleClasses } from "core/utils.d";
import getClassNames from "core/getClassNames";
import Grid from "components/Grid";

interface TabsProps {
  children: JSX.Element | JSX.Element[];
}

export const Tabs = ({ children }: TabsProps) => {
  return (
    <Grid pb={2}>
      <nav className={styles.root}>{children}</nav>
    </Grid>
  );
};

interface TabsItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  isActive: boolean;
}

export const TabsItem = ({ label, isActive, onClick }: TabsItemProps) => {
  let tabsItem: StyleClasses = {
    [styles.root__item]: true,
    [styles.root__item_modifierActive]: isActive,
  };
  const tabsItemNames = getClassNames(tabsItem);

  return (
    <button className={tabsItemNames} onClick={onClick}>
      {label}
    </button>
  );
};
