import differenceInDays from "date-fns/differenceInDays";
import fromUnixTime from "date-fns/fromUnixTime";

const missingDays = (
  unixTimestamp: number,
  t: (key: string) => string,
  today: Date = new Date()
): string => {
  const date = fromUnixTime(unixTimestamp);
  const missingDays = differenceInDays(date, today);

  if (!missingDays) return t("dates.today");

  const label =
    missingDays === 1 || missingDays === -1 ? t("dates.day") : t("dates.days");
  return ` ${missingDays} ${label}`;
};

export default missingDays;
