export enum HTTP_METHODS {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
}

export interface Config {
  body?: any;
  headers?: Record<string, string>;
  method?: HTTP_METHODS;
}

export type HttpCall = (url: string, config?: Config) => Promise<any>;

const http: HttpCall = (url, config = {}) => {
  const request: RequestInit = {
    method: config.method?.valueOf() || "GET",
    headers: new Headers(config?.headers),
    body: config?.body != null ? JSON.stringify(config.body) : undefined,
  };

  return fetch(url, request).then((r) => {
    if (r.status !== 200) {
      throw new Error(r.statusText);
    }
    return r;
  });
};

export default http;
