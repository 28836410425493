import React from "react";
import { useTranslation } from "react-i18next";
import {
  ProductionOrderProduct,
  ProductionOrderInfoEngraving,
} from "core/api/entities";
import Section from "components/Section";
import { Table, Tbody, Tr, Td, Thead, Th } from "components/Table";
import { get } from "lodash";

interface ProductionOrderItemOptionsProps {
  product: ProductionOrderProduct;
}

const ProductionOrderItemOptions = ({
  product,
}: ProductionOrderItemOptionsProps) => {
  const [t] = useTranslation();

  const getProductOptions = () => {
    if (!product) return <span></span>;

    const els: React.ReactElement[] = [];
    product.details?.map((detail: any, i: any) => {
      els.push(
        <Tr key={`1-${i}`}>
          <Td style={{ textAlign: "left", textTransform: "uppercase" }}>
            {detail.attribute}
          </Td>
          <Td style={{ textAlign: "left" }}>{detail.value}</Td>
          <Td style={{ textAlign: "left" }}>{detail.valueCode}</Td>
        </Tr>
      );
      return detail;
    });

    const engravings: ProductionOrderInfoEngraving[] = get(
      product,
      "info.variants[0].engraving",
      []
    );
    engravings.map((engraving: ProductionOrderInfoEngraving, i: number) => {
      els.push(
        <Tr key={`2-${i}`}>
          <Td style={{ textAlign: "left", textTransform: "uppercase" }}>
            {engraving.name}
          </Td>
          <Td style={{ textAlign: "left" }}>{engraving.text}</Td>
          <Td style={{ textAlign: "left" }}>&nbsp;</Td>
        </Tr>
      );
      return engraving;
    });

    return els;
  };

  return (
    <Section>
      <Table>
        <Thead>
          <Tr>
            <Th style={{ textAlign: "left" }}>{t("orders.option")}</Th>
            <Th style={{ textAlign: "left" }}>{t("orders.customer_choice")}</Th>
            <Th style={{ textAlign: "left" }}>
              {t("orders.customer_choice_code")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>{getProductOptions()}</Tbody>
      </Table>
    </Section>
  );
};

export default ProductionOrderItemOptions;
