import React, { useState, useCallback } from "react";
import Button from "components/Button";
import { ParamsObj } from "core/utils";
import { useTranslation } from "react-i18next";
import downloadCSV from "core/downloadCSV";

interface ExportButtonProps {
  filters: ParamsObj;
}

const ExportButton = (props: ExportButtonProps) => {
  const [t] = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { filters } = props;

  const handleExport = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      setDisabled(true);
      await downloadCSV(filters);
      setDisabled(false);
    },
    [filters]
  );

  return (
    <Button
      disabled={disabled}
      label={t("Export")}
      primary={true}
      onClick={handleExport}
    />
  );
};

export default ExportButton;
