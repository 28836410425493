import orders from "core/api/orders";
import download from "core/download";
import { ToastStati } from "core/api/entities";
import toastDelegate from "core/toastDelegate";
import asyncLoadingDelegate from "core/asyncLoadingDelegate";
import i18n from "i18next";

const downloadCSV = async (qsParams = {}) => {
  asyncLoadingDelegate.start();
  try {
    const blob = await orders.ordersAsCSV(qsParams);
    const filename = `Orders.csv`;
    return download(blob, filename);
  } catch (e) {
    toastDelegate.add({
      message: i18n.t("errors.csvDownload"),
      toastStatus: ToastStati.Danger,
    });
  } finally {
    asyncLoadingDelegate.stop();
  }
};

export default downloadCSV;
