import { UserRoles } from "core/api/entities";

import {
  isStatusAnullato,
  isStatusMoreThanSospeso,
  isStatusSospeso,
} from "core/orderStatusUtils";

const isStatusSelectable = (
  role: UserRoles,
  futureOrderStatusId: string,
  currentOrderSatusId: string
) => {
  if (isStatusAnullato(currentOrderSatusId)) {
    return false;
  }

  if (
    role === UserRoles.Retailer &&
    isStatusMoreThanSospeso(futureOrderStatusId)
  ) {
    return false;
  }

  if (
    role === UserRoles.Retailer &&
    isStatusMoreThanSospeso(currentOrderSatusId)
  ) {
    return false;
  }

  if (
    role === UserRoles.Retailer &&
    !isStatusSospeso(currentOrderSatusId) &&
    isStatusAnullato(futureOrderStatusId)
  ) {
    return false;
  }

  return true;
};

export default isStatusSelectable;
