import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { openProductionOrderItemModal } from "./components/ProductionOrderItemModal";
import { openChangeOrderStatusModal } from "./components/ChangeOrderStatusModal";
import ProductionOrderList from "./components/ProductionOrderList";
import Paginator from "components//Paginator";
import orders from "core/api/orders";
import { ProductionOrder, OrderListPagination } from "core/api/dto";
import toastDelegate from "core/toastDelegate";
import WithLanguage, { LanguageProps } from "components/hoc/WithLanguage";
import {
  ToastStati,
  ProductionOrderProduct,
  Status,
  StatusEvent,
  ProductCategory,
} from "core/api/entities";
import { changeStatusOnProducts } from "./Orders.state";
import asyncLoadingDelegate from "core/asyncLoadingDelegate";
import mainBlockingLoadingDelegate from "core/mainBlockingLoadingDelegate";
import { ParamsObj } from "core/utils";
import FiltersComponent from "pages/orders/components/Filters";

const DEFAULT_FILTERS: ParamsObj = {
  term: "",
  sortfield: "order_date",
  sortmode: "DESC",
};

const DEFAULT_PAGINATION: OrderListPagination = {
  pageCount: 0,
  start: 0,
  totalCount: 0,
  hasNext: false,
  hasPrevious: false,
  doNext: null,
  doPrevious: null,
};

interface OrdersProps {
  selectedCategory: ProductCategory;
}

function Orders(props: OrdersProps & LanguageProps) {
  const [t] = useTranslation();
  const [pagination, setPagination] = useState<OrderListPagination>(
    DEFAULT_PAGINATION
  );
  const [orderList, setOrderList] = useState<ProductionOrder[]>([]);
  const [filters, setFilters] = useState<ParamsObj>(DEFAULT_FILTERS);

  const { language, selectedCategory } = props;

  useEffect(() => {
    mainBlockingLoadingDelegate.start();
    orders
      .list({ ...filters, category: selectedCategory.slug })
      .then((response) => {
        setPagination(response.pagination);
        setOrderList(response.orders);
      })
      .catch((e) => {
        toastDelegate.add({
          message: e.message || t("errors.genericRest"),
          toastStatus: ToastStati.Warning,
        });
      })
      .finally(() => {
        mainBlockingLoadingDelegate.stop();
      });
  }, [language, filters, selectedCategory.slug, t]);

  const {
    pageCount,
    start,
    totalCount,
    hasNext,
    doNext,
    hasPrevious,
    doPrevious,
  } = pagination;

  const onNextButtonClick = useCallback(() => {
    if (!hasNext) {
      return;
    }

    if (doNext == null) {
      return;
    }

    mainBlockingLoadingDelegate.start();

    doNext()
      .then((response) => {
        setPagination(response.pagination);
        setOrderList(response.orders);
      })
      .finally(() => mainBlockingLoadingDelegate.stop());
  }, [doNext, hasNext]);

  const onPrevButtonClick = useCallback(() => {
    if (!hasPrevious) {
      return;
    }

    if (doPrevious == null) {
      return;
    }

    mainBlockingLoadingDelegate.start();

    doPrevious()
      .then((response) => {
        setPagination(response.pagination);
        setOrderList(response.orders);
      })
      .finally(() => mainBlockingLoadingDelegate.stop());
  }, [doPrevious, hasPrevious]);

  const onChangeStatus = useCallback(
    async (products: ProductionOrderProduct[]) => {
      const newStatus: Status = await openChangeOrderStatusModal({
        statuses: selectedCategory.statuses,
        selectedProducts: products,
      });
      let lastOrderList = orderList;
      const newOrderList = changeStatusOnProducts(
        orderList,
        products,
        newStatus
      );
      setOrderList(newOrderList);
      try {
        asyncLoadingDelegate.start();
        await orders.changeStatus(products, newStatus);
        toastDelegate.add({
          message: t("toast_generics.success"),
          toastStatus: ToastStati.Success,
        });
      } catch (e) {
        toastDelegate.add({
          message: e.message,
          toastStatus: ToastStati.Warning,
        });
        setOrderList(lastOrderList);
      } finally {
        asyncLoadingDelegate.stop();
      }
    },
    [orderList, selectedCategory.statuses, t]
  );

  const onProductOpen = useCallback(
    async (product: ProductionOrderProduct, order: ProductionOrder) => {
      asyncLoadingDelegate.start();
      const fullProduct: ProductionOrderProduct = await orders.detail(
        product.orderDetailUUID
      );
      const productHistory: StatusEvent[] = await orders.statusHistory(product);
      asyncLoadingDelegate.stop();
      openProductionOrderItemModal({
        product: fullProduct,
        order,
        productHistory,
        statuses: selectedCategory.statuses,
      });
    },
    [selectedCategory.statuses]
  );

  return (
    <div>
      <FiltersComponent
        initialFilters={filters}
        onSubmit={setFilters}
        statuses={selectedCategory.statuses}
      />
      <ProductionOrderList
        data={orderList}
        onChangeStatus={onChangeStatus}
        onProductOpen={onProductOpen}
      />
      <Paginator
        pageCount={pageCount}
        start={start}
        currentCount={orderList.reduce(
          (accumulator: number, order: ProductionOrder) => {
            return accumulator + order.products.length;
          },
          0
        )}
        totalCount={totalCount}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        onNextButtonClick={onNextButtonClick}
        onPrevButtonClick={onPrevButtonClick}
      />
    </div>
  );
}

export default WithLanguage<OrdersProps>(Orders);
