import React from "react";
import { useTranslation } from "react-i18next";
import missingDays from "core/getMissingDays";
import styles from "./TimetableOrderMissingDays.module.scss";
import getClassNames from "core/getClassNames";
import { StyleClasses } from "core/utils.d";

type TimetableOrderProcessDateMissingDaysProps = {
  processDate: number;
  currentDate: number;
};

const TimetableOrderProcessDateMissingDays = ({
  processDate,
  currentDate,
}: TimetableOrderProcessDateMissingDaysProps) => {
  const [t] = useTranslation();

  const PRODUCTION_DATE_IN_MILLISECOND = processDate * 1000;

  let missingDaysClasses: StyleClasses = {
    [styles.root]: true,
    [styles.root_modifierLate]: currentDate > PRODUCTION_DATE_IN_MILLISECOND,
  };
  const missingDaysClassNames = getClassNames(missingDaysClasses);

  const missingDaysResult = missingDays(processDate, t);

  return (
    <div className={styles.missingDays}>
      <div data-testid="missingdays" className={missingDaysClassNames}>
        {missingDaysResult}
      </div>
    </div>
  );
};

export default TimetableOrderProcessDateMissingDays;
