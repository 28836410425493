import Amplify, { Auth } from "aws-amplify";

const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
} = process.env;

Amplify.configure({
  Auth: {
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

export async function getToken(): Promise<string | undefined> {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    return jwt;
  } catch (e) {
    return undefined;
  }
}

export async function logout(): Promise<void> {
  await Auth.signOut();
}
