import orders from "core/api/orders";
import download from "core/download";
import { ProductionOrderProduct, ToastStati } from "core/api/entities";
import toastDelegate from "core/toastDelegate";
import asyncLoadingDelegate from "core/asyncLoadingDelegate";
import i18n from "i18next";

const getBOM = async (row: ProductionOrderProduct, orderId: string) => {
  asyncLoadingDelegate.start();
  try {
    const blob = await orders.getBOM(row.orderDetailUUID);
    const filename = `${orderId}_${row.name}.pdf`;
    download(blob, filename);
  } catch (e) {
    toastDelegate.add({
      message: i18n.t("errors.bomDownload"),
      toastStatus: ToastStati.Danger,
    });
  } finally {
    asyncLoadingDelegate.stop();
  }
};

export default getBOM;
