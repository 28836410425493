import { Toast, ToastLike } from "core/api/entities";

type ChangeCallback = (toastArray: Toast[]) => void;
type Subscribe = (cb: ChangeCallback) => () => void;

const DEFAULT_TOAST_ARRAY: Toast[] = [];

let toastArray: Toast[] = DEFAULT_TOAST_ARRAY;

let changeListeners: ChangeCallback[] = [];

const subscribe: Subscribe = (cb: ChangeCallback) => {
  changeListeners.push(cb);
  return () => {
    changeListeners = changeListeners.filter((t) => t !== cb);
  };
};

const get = () => toastArray;
const add = (_toastLike: ToastLike) => {
  const toast = { ..._toastLike, birth: new Date().getTime() };
  toastArray.push(toast);
  changeListeners.forEach((cb) => cb(toastArray));
};
const remove = (_toastIndex: number) => {
  toastArray.splice(_toastIndex, 1);
  changeListeners.forEach((cb) => cb(toastArray));
};

export default {
  subscribe,
  get,
  add,
  remove,
};
