import Button from "components/Button";
import WithUser, { UserProps } from "components/hoc/WithUser";
import { Status, UserRoles } from "core/api/entities";
import { ParamsObj } from "core/utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExportButton from "./ExportButton";
import styles from "./Filters.module.scss";

interface FiltersComponentProps {
  onSubmit: (f: ParamsObj) => void;
  initialFilters: ParamsObj;
  statuses: Status[];
}
const FiltersComponent = ({
  onSubmit,
  initialFilters,
  statuses,
  user,
}: FiltersComponentProps & UserProps) => {
  const [t] = useTranslation();
  const [filters, setFilters] = useState<ParamsObj>(initialFilters);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newFilters = { ...filters, [e.target.name]: e.target.value };
    setFilters(newFilters);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(filters);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.root}>
      <fieldset className={styles.root__fieldset}>
        <legend className={styles.root__fieldsetLabel}>
          {t("filters.title")}
        </legend>
        <input
          type="text"
          name="term"
          placeholder={t("filters.term")}
          value={filters.term}
          onChange={handleChange}
          className={styles.root__input}
        />
        <select
          name="status"
          value={filters.status}
          onChange={handleChange}
          className={styles.root__input}
        >
          <option value="">{t("filters.all_stati")}</option>
          {statuses.map((s: Status, i: number) => (
            <option key={i} value={s.phaseId}>
              {s.label}
            </option>
          ))}
        </select>
        <select
          name="late"
          value={filters.late}
          onChange={handleChange}
          className={styles.root__input}
        >
          <option value="">{t("filters.all_delivery_schedule")}</option>
          <option value="1">{t("filters.only_late_delivery_schedule")}</option>
        </select>
      </fieldset>

      <fieldset className={styles.root__fieldset}>
        <legend className={styles.root__fieldsetLabel}>
          {t("sorting.title")}
        </legend>
        <select
          name="sortfield"
          value={filters.sortfield}
          onChange={handleChange}
          className={styles.root__input}
        >
          <option value="order_date">{t("orders.date")}</option>
          <option value="delivery_date">
            {t("orders.scheduled_delivery")}
          </option>
          {user?.role !== UserRoles.Retailer && (
            <option value="channel">{t("orders.channel")}</option>
          )}
          {user?.role !== UserRoles.Pm && (
            <option value="customer">{t("customer")}</option>
          )}
        </select>
        <select
          name="sortmode"
          value={filters.sortmode}
          onChange={handleChange}
          className={styles.root__input}
        >
          <option value="DESC">{t("sorting.desc")}</option>
          <option value="ASC">{t("sorting.asc")}</option>
        </select>
      </fieldset>
      <div className={styles.root__btnContainer}>
        <Button
          label={t("search")}
          primary={true}
          onClick={() => onSubmit(filters)}
        />
        &nbsp;
        <ExportButton filters={filters} />
      </div>
    </form>
  );
};

export default WithUser<FiltersComponentProps>(FiltersComponent);
