import React from "react";
import { useTranslation } from "react-i18next";
import styles from "components//Paginator.module.scss";
import Button from "components/Button";

interface PaginatorProps {
  pageCount: number;
  start: number;
  currentCount: number;
  totalCount: number;
  hasNext: boolean;
  hasPrevious: boolean;
  onNextButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onPrevButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type ShowingLabelProps = {
  count: number;
  totalCount: number;
};

const ShowingLabel = (props: ShowingLabelProps) => {
  const [t] = useTranslation();
  const { count, totalCount } = props;
  return (
    <div>
      {t("paginator.showing")} <strong data-testid="pageCount">{count}</strong>{" "}
      {t("paginator.of")} <strong data-testid="totalCount">{totalCount}</strong>
    </div>
  );
};

const Paginator = ({
  pageCount,
  start,
  currentCount,
  totalCount,
  hasNext,
  hasPrevious,
  onNextButtonClick,
  onPrevButtonClick,
}: PaginatorProps) => {
  const isPrevDisabled = !hasPrevious;
  const isNextDisabled = !hasNext;

  const count = currentCount < pageCount ? currentCount : pageCount;
  const stop = isNextDisabled ? totalCount : start + pageCount;

  return (
    <div className={styles.root}>
      <ShowingLabel count={count} totalCount={totalCount} />
      <div>
        <Button
          data-testid="prevButton"
          label="&larr;"
          onClick={onPrevButtonClick}
          small={true}
          disabled={isPrevDisabled}
        />
        &nbsp;&nbsp;&nbsp;
        <span data-testid="start">{start}</span> -{" "}
        <span data-testid="stop">{stop}</span>
        &nbsp;&nbsp;&nbsp;
        <Button
          data-testid="nextButton"
          label="&rarr;"
          onClick={onNextButtonClick}
          small={true}
          disabled={isNextDisabled}
        />
      </div>
    </div>
  );
};

export default Paginator;
