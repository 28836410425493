export interface MainLoadingMessageStatusProps {
  isLoading: boolean;
  message?: "";
}
type SubscribeCallbackType = (
  loadingMessageStatus: MainLoadingMessageStatusProps
) => void;
let changeListeners: SubscribeCallbackType[] = [];

const defaultLoadingStatus = {
  isLoading: false,
};

const defaultBlockingLoadingStartedStatus = {
  isLoading: true,
};

const getDefaultStatus = () => {
  return defaultBlockingLoadingStartedStatus;
};

const subscribe = (cb: SubscribeCallbackType) => {
  changeListeners.push(cb);
  return () => {
    changeListeners = changeListeners.filter((t) => t !== cb);
  };
};

const start = (loadingMessageStatus?: MainLoadingMessageStatusProps) => {
  const msg = loadingMessageStatus
    ? loadingMessageStatus
    : defaultBlockingLoadingStartedStatus;
  changeListeners.forEach((cb) => cb(msg));
};
const stop = () => {
  changeListeners.forEach((cb) => cb(defaultLoadingStatus));
};

export default {
  subscribe,
  getDefaultStatus,
  start,
  stop,
};
