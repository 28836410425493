import fromUnixTime from "date-fns/fromUnixTime";

const toLocaleDateTime = (date: number): string => {
  return `${fromUnixTime(date).toLocaleDateString("it-IT", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })} ${fromUnixTime(date).toLocaleTimeString("it-IT")}`;
};

export default toLocaleDateTime;
